import React from 'react';
import './ServiceBar.scss';
import { Link } from 'react-router-dom';

function ServiceBar() {
    return (
        <div>
            <nav className='service__nav'>
                <Link to='/servicios-prioritaria'><p>C. Externa</p></Link>         
                <Link to='/servicios-ginecologia'><p>Ginecología</p></Link>
                <Link to='/servicios-hospitalizacion'><p>Hospitalización</p></Link>
                <Link to='/servicios-medicina-interna'><p>Medicina interna</p></Link>
                <Link to='/servicios-pediatria'><p>Pediatría</p></Link>
                <Link to='/servicios-cirugia'><p>Cirugía</p></Link>
                <Link to='/servicios-uci-neonatal'><p>UCI Neonatal</p></Link>         
                <Link to='/servicios'><p>UCI Adultos</p></Link>         
                <Link to='/servicios-urgencias'><p>Urgencias</p></Link>
            </nav>
        </div>
    )
}

export default ServiceBar
