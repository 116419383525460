// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQgGQbVnwRDJ8CDXK33loON0Xjwe3n2fs",
  authDomain: "hci-admin-6fb00.firebaseapp.com",
  projectId: "hci-admin-6fb00",
  storageBucket: "hci-admin-6fb00.appspot.com",
  messagingSenderId: "477748205303",
  appId: "1:477748205303:web:9e331a85ff57e4f4b5e5f2",
  measurementId: "G-9MSVDDSR18"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const storage = getStorage(app)
export const db = getFirestore(app);




