import React from 'react';
import './Notification.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import NotificationCard from '../../components/NotificationCard/NotificationCard';

function Notification() {
    return (
        <>
        <Sidebar/>
        <div className='notification'>
            <h2>Notificaciones<span>.</span></h2>
            <div className="notification__container">
                <h4>No tienes notificaciones nuevas</h4>
            </div>
        </div>
        </>
    )
}

export default Notification
