import React from 'react';
import './Dashboard.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import Button from '../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function Quincena() {

    const data = [
        {
            key: '1',
            title: 'No hay datos',
            subtitle: 'Tasa infección'
        },
        {
            key: '2',
            title: 'No hay datos',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'No hay datos',
            subtitle: 'Tipo de infección mas prevalente'
        },
        {
            key: '4',
            title: 'No hay datos',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='dashboard'>
            <h2>Visión General</h2>
            <br />
            <GeneralFilter/>
            <br />
            <h4>Últimos 15 días</h4>
            {/* <h4>Periodo: Enero - Junio</h4> */}
            <div className="dashboard__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                <h5>Tasa de infección</h5>
                <div className="dashboard__graph">
                <Line
                        data={{
                            labels:[],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Microorganismos más prevalentes</h5>
                    <div className="dashboard__graph">
                    <Pie
                        data={{
                            labels:[],
                            datasets: [{
                                label: '',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="dashboard__card-container">
                <div className='dashboard__card-title'>
                <h5>Tipo infección mas prevalente</h5>
                <div className="dashboard__card">
                <Bar
                            data={{
                                labels:[ ],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Servicio con más cultivos positivos</h5>
                <div className="dashboard__card">
                <Doughnut
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Antibioticos mas usados</h5>
                <div className="dashboard__card">
                <Pie
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                {/* <div className='dashboard__card-title'>
                    <h5>Total Cultivos realizados</h5>
                <div className="dashboard__card">
                    <h2>32 cultivos</h2>
                    <p>4 positivos</p>
                </div>
                </div> */}
            
            </div>
        
            {/* <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                    <h5>Costo de antibióticos</h5>
                <div className="dashboard__graph">
                     <Line
                         data={{
                            labels:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
                            datasets: [{
                                label: 'Costo de antibióticos (Millones de pesos)',
                                data: [34.269336, 20.746398, 35.595692, 37.995817, 38.386629, 29.926786],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Egresos</h5>
                    <div className="dashboard__graph">
                    <Line
                        data={{
                            labels:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
                            datasets: [{
                                label: 'Egresos',
                                data: [101, 87, 74, 262, 77, 101, 82],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}
                    />
                    </div>
                </div>
            </div> */}
            <div className="dashboard__graph-container">
                {/* <div className='dashboard__graph-title'>
                    <h5>Reportes</h5>
                    <div className="dashboard__graph">
                    <table>
                        <tr>
                            <th>Documento</th>
                            <th>Periodo</th>
                            <th>Fecha de actualización</th>
                            <th>Acciones</th>
                           
                        </tr>
                        <tr>
                            <td>Reporte mensual</td>
                            <td>Agosto</td>
                            <td>05/09/2021</td>
                            <td className='tb__container'><Button title='Descargar' className='tb__btn'/><Button title='Ver' className='tb__btn--alt'/></td>
                        </tr>
                        <tr>
                            <td>Reporte trimestral</td>
                            <td>Jun-Ago</td>
                            <td>06/10/2021</td>
                            <td className='tb__container'><Button title='Descargar' className='tb__btn'/><Button title='Ver' className='tb__btn--alt'/></td>
                        </tr>
                    </table>
                    </div>
                </div> */}
                {/* <div className="dashboard__graph-title">
                <h5>Usuarios</h5>
                    <div className="dashboard__graph">
                        <table>
                            <tr>
                                <th>Nombre</th>
                                <th>usuario</th>
                                <th>Cargo</th>
                            
                            </tr>
                            <tr>
                                <td>Sandra Orozco</td>
                                <td>coordenfermeria@perfectbody.com.co</td>
                                <td>Jefe de enfermería</td>
                                
                            </tr>
                            <tr>
                                <td>Heidi Gonzales</td>
                                <td>coordenfermeria@perfectbody.com.co</td>
                                <td>Coordinador Médico</td>
                                
                            </tr>
                        </table>
                    </div>
                </div> */}
            </div>
        </div>
        </>
    )
}

export default Quincena
