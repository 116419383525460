import React, { useState } from 'react';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useUserAuth } from '../../providers/UserAuthContext'; 

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [ error, setError] = useState('');
    const { login } = useUserAuth();
    const navigate = useNavigate();

   const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(email, password);
            navigate("/perfil");
        } catch(err) {
            setError('Usuario o contraseña incorrecta')
        }   
   }

    return (
            <div className='login'>
            <Navbar/>
                <div className="login__container">
                    <h2>Login</h2>
                    <form onSubmit={handleLogin}>
                        <Input
                            placeholder='Email'
                            type='email'
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                        <Input
                            placeholder='Contraseña'
                            type='password'
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                        <div className="login__btn">
                                <Button
                                    title='Ingresar'
                                />
                            {     
                                <h5>{error}</h5>  
                            }
                            <br />
                           <a href="mailto:sergio.leal@vieci.com.co?Subject=RE:%20Recuperar%20Contraseña&?body=Solicitud%20para%20recuperar%20mi%20contraseña">¿Recuperar contraseña?</a>
                        </div>
                    </form>
                </div>
             </div>
    )
}

export default Login
