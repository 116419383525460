import React from 'react';
import './Servicios.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import FilterPeriod from '../../components/FilterPeriod/FilterPeriod';
import FilterService from '../../components/FilterService/FilterService';
import ServiceBar from '../../components/ServiceBar/ServiceBar';
import { Line, Doughnut, Bar, Pie } from 'react-chartjs-2';

function Hospitalizacion() {

    const data = [
        {
            key: '1',
            title: '4',
            subtitle: 'Total cultivos positivo'
        },
        {
            key: '2',
            title: 'E. coli',
            subtitle: 'Microorganismo prevalente'
        },
        {
            key: '3',
            title: 'Hemocultivo',
            subtitle: 'Tipo de cultivo prevalente'
        },
        {
            key: '4',
            title: 'No hay datos',
            subtitle: 'Antibiótico mas usado'
        },
    ]

    return (
        <>
        <Sidebar/>
      <div className='servicios'>
          <h2>Servicios<span>.</span></h2>
          <ServiceBar/>
          <div className="servicios__container">
              <FilterService/>
              <h2>Hospitalización</h2>
              <h4>Último mes</h4>
              <div className="servicios__card">
                  {
                      data.map( item => 
                              <InfoCard
                                  key={item.key}
                                  title={item.title}
                                  subtitle={item.subtitle}
                              />
                          )
                  }
              </div>
              <div className="servicios__graph-container">
                  <div className="servicios__graph-title">
                  <h5>Tipo de cultivos </h5>
                  <div className="servicios__graph">
                      <Bar
                          data={{
                              labels:['Catéter','Esputo', 'Hemocultivo', 'Secreción/tejido', 'Urocultivo'],
                              datasets: [{
                                  label: 'Cultivos tomados',
                                  data: [0,0,2,1,1],
                                  backgroundColor: [
                                          '#6C89F5',
                                          '#72F5E8',
                                          '#76F579 ',
                                          '#F5E87C',
                                          '#F5AB5C',
                                          '#F57F79',
                                      ],
                                  borderColor: [
                                          '#6C89F5',
                                          '#72F5E8',
                                          '#76F579 ',
                                          '#F5E87C',
                                          '#F5AB5C',
                                          '#F57F79',
                                      ],
                                  }
                                  ],
                                  
                              }}
                              width={"10%"}
                              options={{ maintainAspectRatio: false }}
                              height={2000}
                      /> 
                  </div>
                  </div>
                  <div className="servicios__graph-title">
                      <h5>Microorganismos más prevalentes</h5>
                  <div className="servicios__graph">
                      <Pie
                              data={{
                                  labels:['E. coli', 'S. marcescens', 'S. cohnii ssp urealyticum', 'S. haemolyticus',],
                                  datasets: [{
                                      label: 'Antibióticos mas usados',
                                      data: [1,1,1,1],
                                      backgroundColor: [
                                              '#6C89F5',
                                              '#72F5E8',
                                              '#76F579 ',
                                              '#F5E87C',
                                              '#F5AB5C',
                                              '#F57F79',
                                          ],
                                      borderColor: [
                                              '#6C89F5',
                                              '#72F5E8',
                                              '#76F579 ',
                                              '#F5E87C',
                                              '#F5AB5C',
                                              '#F57F79',
                                          ],
                                      }
                                      ],
                                      
                                  }}
                                  width={"10%"}
                                  options={{ maintainAspectRatio: false }}
                                  height={2000}
                              /> 
                  </div>
                  </div>
          </div>
          </div>
          <div className="servicios__container">
      </div> 
      </div>
      </>
    )
}

export default Hospitalizacion;
