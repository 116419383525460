import React, { useState, useEffect} from 'react';
import './CrearUsuario.scss';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'
import Sidebar from '../../components/Sidebar/Sidebar';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function CrearUsuario() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const data = [
        {
            label: 'Nombre',
            Value: name,
            type: 'text',
            onChange: (e)=>setName(e.target.value),
        },
        {
            label: 'Correo',
            Value: email,
            type: 'email',
            onChange: (e)=>setEmail(e.target.value),
        },
        {
            label: 'Contraseña',
            Value: password,
            type: 'password',
            onChange: (e)=>setPassword(e.target.value),
        },
        {
            label: 'Confirmar Contraseña',
            Value: confirmPassword,
            type: 'password',
            onChange: (e)=>setConfirmPassword(e.target.value),
        },
    ]

    const navigate = useNavigate();
    const formCollection = collection(db, 'Usuarios nuevos');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addDoc(formCollection, {
            name: name,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
        })
        .then(()=> {
            Swal.fire(
                '¡Exito!',
                'El evento ha sido creado exitosamente',
                'success'
              )
        })
        navigate('/perfil')
        
    }
    
    return (
        <>
        <Sidebar/>
        <div className='crear-usuario'>
            <h2>Crear Usuario<span>.</span></h2>
            <form onSubmit={handleSubmit}>
                <div className="crear-usuario__container">
                    {
                        data.map ( item => 
                                <Input
                                    label={item.label}
                                    type={item.type}
                                    value={item.value}
                                    onChange={item.onChange}

                                />   
                            )
                    }
                    <div className="crear-usuario__select">
                    <label htmlFor="">Permisos</label>
                        <select name="" id="">
                            <option hidden defaultValue>Elige la opción</option>
                            <option value="">Recolección de datos</option>
                            <option value="">Consola administriva</option>
                        </select>
                    </div>
                    <div className="perfil__btn">
                    <Button
                        title='Crear Usuario'
                        className={'profile__btn'}
                    />
                    </div>
                </div>
            </form>
        </div>
        </>
    )
}

export default CrearUsuario
