import React from 'react';
import './Analytics.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiPhone, BiChat, BiBarChart } from "react-icons/bi";
import InfoCard from '../../components/InfoCard/InfoCard';

function Analytics() {

    const data = [
        {
            key: '1',
            title: <BiPhone/>,
            subtitle: 'Perfil',
        },
        {
            key: '2',
            title: <BiChat/>,
            subtitle: 'Soporte'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Vieci Analytics<span>.</span></h2>
            <div className="soporte__container">                      
                    <Link to='/vieci-analytics-iaas'>
                        <InfoCard
                            title={<BiBarChart/>}
                            subtitle='Análisis y monitoreo de IAAS'
                        />    
                    </Link>
            </div>
        </div>
        </>
    )
}

export default Analytics;
