import React from 'react';
import './InfoCard.scss';

function infoCard({ title, subtitle }) {
    return (
        <div className='info-card'>
            <h3>{title}</h3>
            <p>{subtitle}</p>
        </div>
    )
}

export default infoCard
