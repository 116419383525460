import React from 'react';
import './Epidemiologia.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import { BsClipboardCheck, BsClipboardData,BsShop,BsSearch } from "react-icons/bs";

function Epidemiologia() {

    const data = [
        {
            id: '',
            title: <BsSearch size={30}/>,
            subtitle: 'Análisis de IAAS',
            path: '/analisis'
        },
        {
            id: '',
            title: <BsShop size={30}/>,
            subtitle: 'Servicios',
            path: '/servicios'
        },
        {
            id: '',
            title: <BsClipboardData size={30}/>,
            subtitle: 'Antibióticos',
            path: '/antibioticos-costos'
        },
        {
            id: '',
            title: <BsClipboardCheck size={30}/>,
            subtitle: 'Estrategia multimodal',
            path: '/listas-de-chequeo'
        },
    ]
  return (
    <>
    <Sidebar/>
    <div className="epidemiolgia">
        <h2>Epidemiología</h2>
        <div className="epidemiolgia__container">
            {
                   data.map( item => 
                    <Link to={item.path}>
                        <InfoCard
                        title={item.title}
                        subtitle={item.subtitle}                    />
              
                    </Link>
                ) 
            }
        </div>
    </div>
    </>
  )
}

export default Epidemiologia