import React from 'react';
import './Soporte.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiPhone, BiChat, BiUser, BiUserPlus, BiSupport } from "react-icons/bi";
import InfoCard from '../../components/InfoCard/InfoCard';

function Soporte() {

    const data = [
        {
            key: '1',
            title: <BiPhone/>,
            subtitle: 'Perfil',
        },
        {
            key: '2',
            title: <BiChat/>,
            subtitle: 'Soporte'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Configuraciones<span>.</span></h2>
            <div className="soporte__container">
                <Link to="/perfil">
                    <InfoCard
                        title={<BiUser/>}
                        subtitle='Perfil'
                    /> 
                </Link>
                <Link to='/crear-usuario'>
                    <InfoCard
                        title={<BiUserPlus/>}
                        subtitle='Agregar usuario'
                    />    
                </Link>
            
                    <a href="https://wa.me/573004523259?text=Solicito%20soporte%20de%20la%20plataforma%20Vieci">
                        <InfoCard
                            title={<BiSupport/>}
                            subtitle='Soporte'
                        />    
                    </a>
            </div>
        </div>
        </>
    )
}

export default Soporte
