import React from 'react';
import './OptionBar.scss';
import { Link } from 'react-router-dom';

function OptionBar() {
    return (
        <div>
            <nav className='options__nav'>
                <Link to='/antibioticos-costos'><p>Antibióticos</p></Link>
                <Link to='/lista-antibioticos'><p>Lista de antibióticos</p></Link>
            </nav>
        </div>
    )
}

export default OptionBar
