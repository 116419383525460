import React from 'react';
import './Calendario.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Button from '../../components/Button/Button';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from 'react-datepicker';
import moment from 'moment';

function Calendario() {
    const locales = {
      "en-US": require("date-fns/locale/en-US")
    }

    require("moment/locale/es.js")
    const localizer = dateFnsLocalizer ({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
      moment
    })

   const events = [
     {
      title: 'Cove distrital(6)',
      start: new Date(2022,5,1),
      end: new Date(2022,5,1)
     },
     {
      title: 'Cove Sivigila(7)',
      start: new Date(2022,5,30),
      end: new Date(2022,5,30)
     },
     {
      title: 'Comité de infecciones',
      start: new Date(2022,5,26),
      end: new Date(2022,5,26)
     },
     {
      title: 'Cove distrital(7)',
      start: new Date(2022,6,6),
      end: new Date(2022,6,6)
     },
     {
      title: 'Cove Sivigila(8)',
      start: new Date(2022,6,28),
      end: new Date(2022,6,28)
     },
     {
      title: 'Cove distrital(8)',
      start: new Date(2022,7,3),
      end: new Date(2022,7,3)
     },
     {
      title: 'Cumpleaños Bogotá',
      start: new Date(2022,7,6),
      end: new Date(2022,7,6)
     },
  ]


  return (
    <>
    <Sidebar/>
    <div className="calendario">
      <h2>Calendario<span className='calendario-span'>.</span></h2>
      <div className="calendario-form">
        <Link to='/evento'>
          <Button
            title='Agregar evento'
            className='btn'
          />  
        </Link>
      
      </div>
      <div className="calendario-container">
        <Calendar localizer={localizer} events={events} 
        startAccessor="start" endAccessor="end"
        style={{ height: "500", margin:"20px"}} />
      </div>
    </div>
    </>
  )
}

export default Calendario