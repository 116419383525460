import React from 'react';
import './Demo.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';

function Demo() {
  return (
      <>
      <Sidebar/>
      <div className='demo'>
        <h2>Nombre antibiótico</h2>
      </div>
      </>
  )
}

export default Demo;
