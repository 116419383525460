import React, { useState } from 'react';
import './Documents.scss';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Sidebar from '../../components/Sidebar/Sidebar';
import Docbar from '../../components/Docbar/Docbar';
import Button from '../../components/Button/Button';

function Documents() {

    const data = [
        {
            id: '1',
            type: 'Ficha notificación',
            name: 'Consumo de antibióticos',
            code: 'INS 354',
            link: <a href="https://drive.google.com/file/d/1-wLHyIoCdH_WAFi9S1Mj7z4P0fniTj0l/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '2',
            type: 'Ficha notificación',
            name: 'Infección Asociada a Dispositivos - IAD en UCI (Unidad de Cuidados Intensivos)',
            code: 'INS 359',
            link: <a href="https://drive.google.com/file/d/1H2HBn2vClmDCfjKqVBmg2qLNR_MgsTz1/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '3',
            type: 'Ficha notificación',
            name: 'Infección Asociada a Dispositivos - IAD en UCI',
            code: 'INS 357',
            link: <a href="https://drive.google.com/file/d/1UW8kmKEWEe7blsV_jUbrYx3vFzEAOT74/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '4',
            type: 'Ficha notificación',
            name: 'Infecciones de sitio quirúrgico asociadas a procedimiento médico quirúrgico',
            code: 'INS 352',
            link: <a href="https://drive.google.com/file/d/1ujZ7z6KCeCOh5BOiJSOqU1A5fPPvz3Yl/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '5',
            type: 'Ficha notificación',
            name: 'Endometritis puerperal Código',
            code: 'INS 351',
            link: <a href="https://drive.google.com/file/d/1ujZ7z6KCeCOh5BOiJSOqU1A5fPPvz3Yl/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '6',
            type: 'Ficha notificación',
            name: 'Endometritis puerperal Código',
            code: 'INS 110',
            link: <a href="https://drive.google.com/file/d/1ujZ7z6KCeCOh5BOiJSOqU1A5fPPvz3Yl/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '7',
            type: 'Ficha notificación',
            name: 'Deféctos congénitos',
            code: 'INS 215',
            link: <a href="https://www.ins.gov.co/buscador-eventos/Lineamientos/215_Defectos_Congenitos_2022.pdf" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '8',
            type: 'Ficha notificación',
            name: 'Infección respiratoria aguda grave-IRAG-Inusitada',
            code: 'INS 348',
            link: <a href="https://www.ins.gov.co/buscador-eventos/Lineamientos/348_Irag_inusitado_2022.pdf" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '9',
            type: 'Ficha notificación',
            name: 'Hepatitis B, C y coinfección hepatitis B y Delta',
            code: 'INS 348',
            link: <a href="https://www.ins.gov.co/buscador-eventos/Lineamientos/340_Hepatitis_B_2022.pdf" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },

    ]

    const [search, setSearch] = useState('');

    const filterData = data.filter((data)=> {
       return data.name.toLowerCase().includes(search.toLowerCase())
   })

    const handleSearch = (e) => {
       setSearch(e.target.value)
    }
     
    return (
        <>
        <Sidebar/>
        <div className='documentos'>
            <h2>Documentos<span>.</span></h2>
            <Docbar /> 
            <div className="documentos__container">
                <h3>Fichas de notificación</h3>
                <div className="documentos__search">
                    <Input
                        type='text'
                        placeholder={'Buscar formato'}
                        value={search}
                        onChange={handleSearch}
                    />
                </div>
                <table>
                        <tr>
                            <th><b>Tipo de Documento</b></th>
                            <th><b>Nombre del documento</b></th>
                            <th><b>Código</b></th>
                            <th><b>Acciones</b></th>
                           
                        </tr>
                        {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.type}</td>
                                            <td>{item.name}</td>
                                            <td>{item.code}</td>
                                            <td className='tb__container'>{item.link}</td>
                                        </tr>
                                        )
                                }
                    </table>
            </div>
        </div>
        </>
    )
}

export default Documents
