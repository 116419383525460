import { createContext, useContext, useState, useEffect} from "react";
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState('')
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
    return signOut(auth);
    }

    useEffect(() => {
       const unsuscribe =  onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        });
        return () => {
            unsuscribe();
        }
    }, []);
    
    return (
        <userAuthContext.Provider value={{user, login, logOut}}>
            {children}
        </userAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(userAuthContext);
}