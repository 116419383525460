import React, { useState } from 'react';
import './SubirArchivos.scss';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Button from '../../components/Button/Button';
import Swal from 'sweetalert2';
import {getDownloadURL, ref ,uploadBytesResumable } from '@firebase/storage';
import { storage} from '../../firebase/firebaseConfig';

function SubirArchivos() {  
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const handleForm = (e) => {
        e.preventDefault();
        const file = (e.target[0].files[0]);
        handleUpload(file)
    }

     const handleUpload = (file) => {
         if(!file) return;
         const storageRef = ref(storage, `/files/${file.name}`);
         const uploadTask = uploadBytesResumable(storageRef, file);

         uploadTask.on("state_changed",(snapshot)  => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setProgress(prog)
         }, (err)=> console.log(err),
            ()=> {
                getDownloadURL(uploadTask.snapshot.ref)
                .then(url => console.log(url))
                .then(()=> Swal.fire(
                    '¡Exito!',
                    'El documento fue sibido exitosamente',
                    'success'
                  )
                  .then(()=> {
                    navigate('/upload')
                  })
                )
            }
         )
     };

  return (
    <>
    <Sidebar/>
    <div className="archivos">
        <h2>Cargar archivos<span>.</span></h2>
        <div className="archivos__container">
            <div className="archivos__form">
                <form onSubmit={handleForm}>
                {/* <label htmlFor=""><b>¿Que tipo de archivo vas a subir?</b></label>
                <select className='archivos__input' onChange={(e)=>setServicio(e.target.value)}>
                                <option hidden defaultValue>Elige el periodo</option>
                                    <option value='Historia clínica'>Historia Clínica</option>
                                    <option value='Cultivos'>Cultivos</option>
                                    <option value='Antibióticos'>Antibióticos</option>
                </select> */}
                <div className="archivos__upload">
                    <input type='file' className='archivos__file' />     
                    <p>Haz click aquí para subir los archivos</p> 
                    <br />   
                    <small>Por favor asegurate que estás subiendo un archivo con los siguientes formatos: PDF, xlsx o texto plano</small>
                </div>
                <Button
                    title='Subir archivo'
                    className='archivos__btn'
                />
                <br />
                <p>Progreso: {progress}%</p>
                </form>
            </div>
        </div>
    </div>
    </>
  )
};

export default SubirArchivos;