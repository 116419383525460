import React from 'react';
import './IAAS.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import InfoCard from '../../../components/InfoCard/InfoCard';
import GeneralFilter from '../../../components/GeneralFilter/GeneralFilter';
import Button from '../../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function IAAS() {

    const data = [
        {
            key: '1',
            title: '130',
            subtitle: 'Total de cultivos positivos'
        },
        {
            key: '2',
            title: 'E. coli',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'Urocultivo',
            subtitle: 'Tipo de cultivo mas tomado'
        },
        {
            key: '4',
            title: 'Urgencias',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='iaas'>
            <h2>Análisis y monitro de IAAS<span>.</span></h2>
            <br />
            <GeneralFilter/>
            <br />
            <h4>Último mes</h4>
            <Link to='/upload'>
                <Button
                    title='Cargar archivos'
                    className='iaas__btn'
                />
            </Link>
            
            <div className="iaas__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            
            <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                <h5>Tasa de infección</h5>
                <div className="dashboard__graph">
                <Line
                        data={{
                            labels:['hola', 'jenny'],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Microorganismos mas prevalentes</h5>
                    <div className="dashboard__graph">
                    <Pie
                        data={{
                            labels:['E. coli', 'K. aerogenes', ' K. pneumoniae', 'S. aureus', 'P. mirabilis'],
                            datasets: [{
                                label: '',
                                data: [75,3,6,16,4],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="dashboard__card-container">
                <div className='dashboard__card-title'>
                <h5>Tipo de cultivo</h5>
                <div className="dashboard__card">
                <Bar
                            data={{
                                labels:['Hemocultivo', 'Secreción/tejido', 'Urocultivo', 'Catéter', 'Esputo'],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [13, 43, 74, 0, 0],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Antibióticos más usados</h5>
                <div className="dashboard__card">
                <Doughnut
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Cultivos por servicio</h5>
                <div className="dashboard__card">
                <Bar
                         data={{
                            labels:['Hospitalización', 'UCI Neonatal', 'UCI Adultos', 'Cirugia', 'C. externa', 'Urgencias', 'Pediatría', 'Medicina interna', 'Ginecología'],
                            datasets: [{
                                label: 'Servicio con mas cultivos positivos',
                                data: [4, 7, 23, 14, 13, 43, 7, 8, 10 ],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default IAAS;
