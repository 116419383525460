import React, { useState } from 'react';
import './ListaAntibioticos.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import OptionBar from '../../components/OptionBar/OptionBar';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function ListaAntibioticos() {

    const data = [
        {
            key: '1',
            codigo: 'NP15180190222',
            nombre: 'ACIDO NALIDIXICO',
            concentracion: '250MG/5ML',
            presentacion: 'SUSPENSION X 120ML',
            costo: '40.835 ',
            caracteristicas: '-',
            actions:<Link to='/perfil-antibiotico'><Button title='Ver' className='tb__btn--alt'/></Link>,
        },
        {
            key: '2',
            codigo: 'J01GA020701',
            nombre: 'AMIKACINA  ',
            concentracion: '100 MG/2 ML ',
            presentacion: 'AMPOLLA',
            costo: '- ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '3',
            codigo: 'J01GA020702',
            nombre: 'AMIKACINA',
            concentracion: '500 MG 2 ML',
            presentacion: 'AMPOLLA',
            costo: '1.618 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
         {
             key: '4',
             codigo: 'J01CA025232',
             nombre: 'AMOXICILINA ',
             concentracion: '250MG/5ML X 100 ML',
             presentacion: 'SUSPENSION',
             costo: '4.105 ',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
             key: '5',
             codigo: 'J01CA025142',
             nombre: 'AMOXICILINA',
             concentracion: '500 MG',
             presentacion: 'CAPSULA',
             costo: '265',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
             key: '6',
             codigo: 'J01CA028721',
             nombre: 'AMPICILINA + SULBACTAM',
             concentracion: '1.5 GR',
             presentacion: 'AMPOLLA',
             costo: '2.231',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
             key: '7',
             codigo: 'J01CA026722',
             nombre: 'AMPICILINA ',
             concentracion: '1 GR ',
             presentacion: 'AMPOLLA',
             costo: '1.566 ',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
             key: '8',
             codigo: 'J01CA026721',
             nombre: 'AMPICILINA ',
             concentracion: '500 ',
             presentacion: 'AMPOLLA',
             costo: '1.809',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
             key: '9',
             codigo: 'J01CA027142',
             nombre: 'AMPICILINA',
             concentracion: '500 MG',
             presentacion: 'CAPSULA',
             costo: ' 285 ',
             caracteristicas: '-',
             actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
         {
            key: '10',
            codigo: '117011',
            nombre: 'AZITROMICINA',
            concentracion: '200 MG/5ML  ',
            presentacion: 'SUSPENSION 15ML',
            costo: '15.978 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
         },
        {
            key: '11',
            codigo: 'JO1CA025143',
            nombre: 'AZITROMICINA ',
            concentracion: '500 MG ',
            presentacion: 'TABLETA',
            costo: '651 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '12',
            codigo: 'J01DA035721',
            nombre: 'AZTREONAM  ',
            concentracion: 'X 1G POLVO ',
            presentacion: 'LIOFILIZADO',
            costo: '21.637 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '13',
            codigo: 'J02AX0401',
            nombre: 'CASPOFUNGINA',
            concentracion: '50 MG ',
            presentacion: 'AMPOLLA',
            costo: '421.206 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '14',
            codigo: 'J01DC008232',
            nombre: 'CEFALEXINA ',
            concentracion: '250 MG/ 5 ML 60 ML  ',
            presentacion: 'SUSPENSION',
            costo: '3.032 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '15',
            codigo: 'J01DC008142',
            nombre: 'CEFALEXINA ',
            concentracion: '500 MG ',
            presentacion: 'TABLETA',
            costo: '411 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '16',
            codigo: 'J01DC043721',
            nombre: 'CEFALOTINA',
            concentracion: '1 GR ',
            presentacion: 'AMPOLLA',
            costo: '3.301 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '17',
            codigo: 'J01DC044721',
            nombre: 'CEFAZOLINA',
            concentracion: '1 GR ',
            presentacion: 'AMPOLLA',
            costo: '3.017 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '18',
            codigo: 'J01DE01',
            nombre: 'CEFEPIME',
            concentracion: '1 GR',
            presentacion: 'AMPOLLA',
            costo: '5.178 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '19',
            codigo: 'NP151801014',
            nombre: 'CEFOTAXIME',
            concentracion: '1 GR  ',
            presentacion: 'AMPOLLA',
            costo: '7.560 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '20',
            codigo: 'J01DC011724',
            nombre: 'CEFTAZIDIMA',
            concentracion: '1 GR  ',
            presentacion: 'AMPOLLA',
            costo: '6.250 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '21',
            codigo: 'J01DD02',
            nombre: 'CEFTAZIDIME',
            concentracion: '2G + AVIBACTAM 0.5G',
            presentacion: 'POLVO LIOFILIZADO PARA SOLUCION INYECTABLE',
            costo: '644.837 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '22',
            codigo: 'J01DI54',
            nombre: 'CEFTOLOZANO',
            concentracion: '1.0G + TAZOBACTAM 0.5G',
            presentacion: 'POLVO PARA RECONSTITUIR',
            costo: '312.800 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '23',
            codigo: 'J01DC011723',
            nombre: 'CEFTRIAXONA',
            concentracion: '1 GR',
            presentacion: 'AMPOLLA',
            costo: '4.833 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '24',
            codigo: '2185603',
            nombre: 'CEFUROXIMA ',
            concentracion: '250MG/5ML ',
            presentacion: 'SUSPENSION',
            costo: '75.985 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '25',
            codigo: '48399',
            nombre: 'CIPROFLOXACINO ',
            concentracion: '0.3% ',
            presentacion: 'GOTAS OTICO - OFTALMICAS',
            costo: '75.985 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '26',
            codigo: 'J01MC015013',
            nombre: 'CIPROFLOXACINO ',
            concentracion: '0.3%  ',
            presentacion: 'SOLUCION OFTALMICA X 5 ML',
            costo: '18.831 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '27',
            codigo: 'J01MC015012',
            nombre: 'CIPROFLOXACINO ',
            concentracion: '500 MG ',
            presentacion: 'TABLETA',
            costo: '467 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '28',
            codigo: 'J01MC015701',
            nombre: 'CIPROFLOXACINO CLORHIDRATO ',
            concentracion: '100 MG/10 ML',
            presentacion: 'AMPOLLA',
            costo: '2.656 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '29',
            codigo: '19910677',
            nombre: 'CIPROFLOXACINO',
            concentracion: '3MG/ML',
            presentacion: 'GOTAS ÓTICAS ',
            costo: '13.529 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '30',
            codigo: 'J01FC046721',
            nombre: 'CLARITROMICINA ',
            concentracion: '500 MG ',
            presentacion: 'AMPOLLA',
            costo: '25.985 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '31',
            codigo: 'B05BA02170103',
            nombre: 'CLINDAMICINA ',
            concentracion: '300 MG',
            presentacion: 'CAPSULA',
            costo: '9.167 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '32',
            codigo: 'J01FC019701',
            nombre: 'CLINDAMICINA ',
            concentracion: '600 MG ',
            presentacion: 'AMPOLLA',
            costo: '4.140 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '33',
            codigo: 'J01XB0101',
            nombre: 'COLISTIMETATO DE SODIO ',
            concentracion: 'VIAL DE 1000000 UI',
            presentacion: '-',
            costo: '56.140 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '34',
            codigo: 'J01XB0102',
            nombre: 'COLISTIMETATO DE SODIO',
            concentracion: ' X 150 MG ',
            presentacion: '(COLISTINA)',
            costo: '70.313 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '35',
            codigo: 'J01CD016232',
            nombre: 'DICLOXACILINA ',
            concentracion: '250 MG/5ML ',
            presentacion: 'SUSPENSION',
            costo: '4.988 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '36',
            codigo: 'J01CD016102',
            nombre: 'DICLOXACILINA',
            concentracion: '500 MG ',
            presentacion: 'CAPSULA',
            costo: '311 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '37',
            codigo: 'J01AD031141',
            nombre: 'DOXICICLINA',
            concentracion: '100 MG',
            presentacion: 'CAPSULA',
            costo: '183 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '38',
            codigo: 'J01FE007231',
            nombre: 'ERITROMICINA ',
            concentracion: '250 MG/5 ML ',
            presentacion: 'SUSPENSION X 60 ML',
            costo: '13.561 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '39',
            codigo: 'J01FE007141',
            nombre: 'ERITROMICINA',
            concentracion: '500 MG',
            presentacion: 'TABLETA',
            costo: '1.141 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '40',
            codigo: '019931619-01',
            nombre: 'ERTAPENEM',
            concentracion: '1 GRAMO',
            presentacion: 'AMPOLA',
            costo: '110.250 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '41',
            codigo: 'NP15180190311',
            nombre: 'GENTAMICINA',
            concentracion: '0.1% 40G',
            presentacion: 'CREMA TOPICA',
            costo: '1.781 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '42',
            codigo: 'S01AG003601',
            nombre: 'GENTAMICINA ',
            concentracion: '0.3 % ',
            presentacion: 'GOTAS OFTALMICAS X 6 ML',
            costo: '2.377 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '43',
            codigo: 'S01AG003611',
            nombre: 'GENTAMICINA',
            concentracion: '0.3 %',
            presentacion: 'UNGUENTO OFTALMICO',
            costo: '6.800 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '44',
            codigo: 'J01GG003701',
            nombre: 'GENTAMICINA',
            concentracion: '20 MG',
            presentacion: 'AMPOLLA',
            costo: '875 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '45',
            codigo: 'J01GG003702',
            nombre: 'GENTAMICINA',
            concentracion: '40 MG ',
            presentacion: 'AMPOLA',
            costo: '1.222 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '46',
            codigo: 'J01GG003703',
            nombre: 'GENTAMICINA',
            concentracion: '80 MG ',
            presentacion: 'AMPOLA',
            costo: '762 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '47',
            codigo: 'J01DI004721',
            nombre: 'IMIPENEM ',
            concentracion: '500 mg + CILASTATINA 500 ',
            presentacion: 'FCO',
            costo: '20.672 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '48',
            codigo: 'J01XX08',
            nombre: 'LINEZOLID',
            concentracion: '2 MG/ML',
            presentacion: 'SOLUCION INYECTABLE FRASCO POR 300 ML',
            costo: '69.108 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '49',
            codigo: '19948322',
            nombre: 'MEROPENEM',
            concentracion: '1G',
            presentacion: 'FCO',
            costo: '17.706 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '50',
            codigo: 'J01CM026721',
            nombre: 'MEROPENEM ',
            concentracion: '500 MG ',
            presentacion: 'FCO',
            costo: '14.776 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '51',
            codigo: '199894001',
            nombre: 'MOXIFLOXACINO',
            concentracion: '0.5%',
            presentacion: 'GOTAS OFTÁLMICAS ',
            costo: '13.867 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '52',
            codigo: '19902059',
            nombre: 'MOXIFLOXACINO',
            concentracion: '400MG',
            presentacion: 'AMPOLLA',
            costo: '76.000 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '53',
            codigo: 'J01MN012141',
            nombre: 'NORFLOXACINA ',
            concentracion: '400 MG ',
            presentacion: 'TABLETA',
            costo: '120 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '54',
            codigo: 'J01CO004721',
            nombre: 'OXACILINA',
            concentracion: '1 GR ',
            presentacion: 'AMPOLLA',
            costo: '2.091 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '55',
            codigo: 'J01CP005721',
            nombre: 'PENICILINA G BENZATINICA',
            concentracion: '1.200.000 UI',
            presentacion: ' FCO AMPOLLA',
            costo: '1.689 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '56',
            codigo: 'J01CP005722',
            nombre: 'PENICILINA G BENZATINICA  ',
            concentracion: '2.400.000 UI',
            presentacion: 'FCO AMP',
            costo: '2.552 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '57',
            codigo: 'J01CP006722',
            nombre: 'PENICILINA G PROCAINICA ',
            concentracion: '800.000 UI',
            presentacion: 'FCO AMP',
            costo: '675 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '58',
            codigo: 'J01CP007721',
            nombre: 'PENICILINA G SODICA',
            concentracion: '1.000.000 UI',
            presentacion: 'AMPOLLA',
            costo: '1.460 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '59',
            codigo: 'J01CP007722',
            nombre: 'PENICILINA G SODICA ',
            concentracion: ' 5.000.000 UI',
            presentacion: 'AMPOLLA',
            costo: '1.534 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '60',
            codigo: 'J01CP037721',
            nombre: 'PIPERACILINA + TAZOBACTAM  ',
            concentracion: '4.5 GR',
            presentacion: 'AMPOLLA',
            costo: '12.990 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '61',
            codigo: 'J01AT008101',
            nombre: 'TETRACICLINA ',
            concentracion: '500 MG ',
            presentacion: 'CAP',
            costo: '145 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '62',
            codigo: 'J01AA1201',
            nombre: 'TIGECICLINA ',
            concentracion: '50 MG ',
            presentacion: 'AMP',
            costo: '49.590 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        },
        {
            key: '63',
            codigo: 'J01XV013721',
            nombre: 'VANCOMICINA  ',
            concentracion: '500MG/10ML',
            presentacion: 'AMPOLLA',
            costo: '7.206 ',
            caracteristicas: '-',
            actions: <Button title='Ver' className='tb__btn--alt'/>,
        }
    ]

     const [search, setSearch] = useState('');

     const filterData = data.filter((data)=> {
        return data.nombre.toLowerCase().includes(search.toLowerCase())
    })

     const handleSearch = (e) => {
        setSearch(e.target.value)
     }
    return (
        <>
        <Sidebar/>
        <div className='antibioticos'>
            <h2>Antibióticos<span>.</span></h2>
            <OptionBar/>
            <div className="antibioticos__container">
                <h4>Listado de antibibióticos</h4>
                <div className="antibioticos__list">
                    <Input
                        type='text'
                        placeholder='Buscar antibiótico...'
                        value={search}
                        onChange={handleSearch}
                    />
                    <br />
                    <table >
                            <tr>
                                <th><b>Código</b></th>
                                <th><b>Antibiótico</b> </th>
                                <th><b>Concentración por unidad</b></th>
                                <th><b>Presentación</b></th>
                                <th><b>Valor compra unidad</b></th>
                                {/* <th><b>Restricción</b></th> */}
                                <th><b>Acciones</b></th>
                            </tr>
                                {
                                    filterData.map( item => 
                                        <tr>
                                            <td><small>{item.codigo}</small> </td>
                                            <td>{item.nombre}</td>
                                            <td>{item.concentracion}</td>
                                            <td>{item.presentacion}</td>
                                            <td>${item.costo}</td>
                                            {/* <td>{item.caracteristicas}</td> */}
                                            <td className='tb__container'>{item.actions}</td>
                                        </tr>
                                        )
                                }
                      
                        </table>
                </div>
            </div>
        </div>
        </>
    )
}

export default ListaAntibioticos
