import React from 'react';
import './Perfil.scss';
import Sidebar from '../../components/Sidebar/Sidebar';


function Perfil() {


    return (
        <>
        <Sidebar/>
        <div className='perfil'>
            <h2>Perfil<span>.</span></h2>  
            <div className="perfil__container">
                <p>Institución</p>
                <input placeholder='Hospital Civil de Ipiales' readOnly/>
                <p>Ciudad</p>
                <input placeholder='Ipiales, Nariño' readOnly/>
                <p>Dirección</p>
                <input placeholder=' Carrera 1 No 4A- 142 Este - Avenida Panamericana Norte' readOnly/>
                <p>Teléfono</p>
                <input placeholder='(602) 7732234' readOnly/>   
            </div>
        </div>
        </>
    )
}

export default Perfil
