import React from 'react';
import './Microorganismos.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import FilterPeriod from '../../components/FilterPeriod/FilterPeriod';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function Microorganismos() {

    const data = [
        {
            key: '1',
            title: 'No hay datos',
            subtitle: 'Total cultivos tomados'
    
        },
        {
            key: '2',
            title: 'No hay datos',
            subtitle: 'Total cultivos positivos'
        },
        {
            key: '3',
            title: 'No hay datos',
            subtitle: 'Microorganismo mas prevalente'
        },
        {
            key: '4',
            title: 'No hay datos',
            subtitle: 'Muestra con mas cultivos positivos'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='microorganismos'>
            <h2>Cultivos<span>.</span></h2>
            <FilterPeriod/>
            <h4>Últimas 24 horas</h4>
            <div className="microorganismos__info">
                {
                    data.map(item =>
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
            </div>
            <div className="microorganismos__graph-container--alt">
                <div className='microorganismos__graph-title'>
                    <h5>Cultivos tomados</h5>
                <div className="microorganismos__graph--alt">
                <Bar
                            data={{
                                labels:[],
                                datasets: [{
                                    label: 'Cultivos tomados',
                                    data: [],
                                    backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}

                        />
                </div>
                </div>
                <div className="microorganismos__graph-title">
                <h5>Cultivos positivos</h5>
                    <div className="microorganismos__graph--alt">
                    <Pie
                        data={{
                            labels:['Positivo', 'Negativo'],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="microorganismos__graph-container--alt">
                <div className='microorganismos__graph-title'>
                    <h5>Microorganismo mas prevalente</h5>
                <div className="microorganismos__graph--alt2">
                    <Doughnut
                        data={{
                            labels:[],
                            datasets: [{
                                label: 'Morfología y clasificación de Gram en aislamientos',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="microorganismos__graph-title">
                <h5>Cultivos positivos vs negativos</h5>
                    <div className="microorganismos__graph--alt2">
                        <Pie
                            data={{
                                labels:['Negativos','Positivos'],
                                datasets: [{
                                    label: 'Cultivos positivos vs negativos',
                                    data: [],
                                    backgroundColor: [
                                        '#76F579 ',
                                        '#F5AB5C',
                                    ],
                                    borderColor: [
                                        '#76F579 ',
                                        '#F5AB5C',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                    </div>
                </div>
                <div className="microorganismos__graph-title">
                <h5>Cultivos positivos por muestras</h5>
                    <div className="microorganismos__graph--alt2">
                        <Bar
                            data={{
                                labels:[],
                                datasets: [{
                                    label: 'Bacilos gram negativos aislados',
                                    data: [],
                                    backgroundColor: [
                                        '#76F579 ',
                                        '#F5AB5C',
                                    ],
                                    borderColor: [
                                        '#76F579 ',
                                        '#F5AB5C',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Microorganismos
