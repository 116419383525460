import React from 'react';
import './Navbar.scss';
import Eye from '../../assets/brand/eye.png';

function Navbar() {
    return (
        <>
        <div className='navbar'>
            <header>
               <h2>Vieci<span>.</span></h2>
            </header>
        </div>
        </>
    )
}

export default Navbar;
