import React from 'react';
import './Dashboard.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import Button from '../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function Trimestre() {

    const data = [
        {
            key: '1',
            title: '349',
            subtitle: 'Total de cultivos positivos'
        },
        {
            key: '2',
            title: 'E. coli',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'Urocultivo',
            subtitle: 'Tipo de cultivo mas tomado'
        },
        {
            key: '4',
            title: 'Urgencias',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='dashboard'>
            <h2>Visión General<span>.</span></h2>
            <br />
            <GeneralFilter/>
            <br />
            <h4>Último trimestre</h4>
            <div className="dashboard__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                <h5>Cultivos tomados</h5>
                <div className="dashboard__graph">
                <Line
                        data={{
                            labels:['Junio', 'Julio', 'Agosto'],
                            datasets: [{
                                label: 'Total de cultivos',
                                data: [111, 108, 130],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Microorganismos mas prevalentes</h5>
                    <div className="dashboard__graph">
                    <Pie
                        data={{
                            labels:['S. epidermidis','E. coli',' K. pneumoniae', 'P. mirabilis', 'S. aureus', ],
                            datasets: [{
                                label: '',
                                data: [12, 200, 13, 15, 42],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="dashboard__card-container">
                <div className='dashboard__card-title'>
                <h5>Tipo de cultivo</h5>
                <div className="dashboard__card">
                <Bar
                            data={{
                                labels:['Catéter', 'Esputo', 'Hemocultivo', 'Secreción/tejido', 'Urocultivo'],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [5, 2, 31, 128, 183],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Tasa de infección</h5>
                <div className="dashboard__card">
                <Line
                         data={{
                            labels:['Junio', 'Julio', 'Agosto'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Cultivos positivos por servicio</h5>
                <div className="dashboard__card">
                <Bar
                         data={{
                            labels:['Hospitalización', 'UCI Neonatal', 'UCI Adultos', 'Cirugia', 'C. externa', 'Urgencias', 'Pediatría', 'Medicina interna', 'Ginecología'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [18, 13, 57, 36, 29, 128, 18, 28, 20],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default Trimestre;
