import React from 'react';
import './Costos.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
import OptionBar from '../../../components/OptionBar/OptionBar';
import InfoCard from '../../../components/InfoCard/InfoCard';
import FilterAntibiotic from '../../../components/FilterAntibiotic/FilterAntibiotic';
import { Line, Doughnut } from 'react-chartjs-2';

function Costos() {

    const data = [
        {
            key: '1',
            title: 'No hay datos',
            subtitle: 'Antibiótico más usado'
        },
        {
            key: '2',
            title: 'No hay datos',
            subtitle: 'Costo total'
        },
        {
            key: '3',
            title: 'No hay datos',
            subtitle: 'Antibiótico más usado'
        },
        {
            key: '4',
            title: 'No hay datos',
            subtitle: 'Costo total'
        }
    ]

    return (
        <>
        <Sidebar/>
        <div className='costos'>
            <h2>Antibióticos.</h2>
            <OptionBar/> 
            <div className="costos__container">
                <h2>Uso de Antibióticos</h2>
                <FilterAntibiotic/>
                <h4>Últimas 24 horas</h4>
                <div className="costos__card">
                  {
                      data.map( item => 
                            <InfoCard
                                key={item.key}
                                title={item.title}
                                subtitle={item.subtitle}
                                className='costos__card-alt'
                            />
                            )
                        }
            </div>
            <div className="costos__graph-container">
                <div className="costos__graph">
                    <Line
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Costo de antibióticos',
                                data: [],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                <div className="costos__graph">
                    <Doughnut
                              data={{
                                labels:[],
                                datasets: [{
                                    label: 'Antibióticos mas costosos en Enero',
                                    data: [],
                                    backgroundColor: [
                                            '#6C89F5',
                                            '#72F5E8',
                                            '#76F579 ',
                                            '#F5E87C',
                                            '#F5AB5C',
                                            '#F57F79',
                                        ],
                                    borderColor: [
                                            '#6C89F5',
                                            '#72F5E8',
                                            '#76F579 ',
                                            '#F5E87C',
                                            '#F5AB5C',
                                            '#F57F79',
                                        ],
                                    }
                                    ],
                                    
                                }}
                                width={"10%"}
                                options={{ maintainAspectRatio: false }}
                                height={2000}
                            /> 
                </div>
            </div>
            <br />
            <div className="costos__table">
         
            </div>
            </div>
        </div>
        </>
    )
}

export default Costos
