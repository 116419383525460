import React from 'react';
import './Checklist.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiPhone, BiChat, BiBarChart,} from "react-icons/bi";
import { BsClipboardCheck} from "react-icons/bs";
import InfoCard from '../../components/InfoCard/InfoCard';

function Checklist() {

    const data = [
        {
            key: '1',
            title: <BiPhone/>,
            subtitle: 'Perfil',
        },
        {
            key: '2',
            title: <BiChat/>,
            subtitle: 'Soporte'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Vieci Checklists<span>.</span></h2>
            <div className="soporte__container">                      
                    <Link to='/listas-de-chequeo'>
                        <InfoCard
                            title={<BsClipboardCheck/>}
                            subtitle='Higiene de manos'
                        />    
                    </Link>
            
                    <Link to='/listas-de-chequeo-asepsia'>
                        <InfoCard
                            title={<BsClipboardCheck/>}
                            subtitle='Asepsia y antiasepsia'
                        />    
                    </Link>
            </div>
        </div>
        </>
    )
}

export default Checklist;
